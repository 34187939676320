import { Container } from "@mui/material";
import { HomePageBody } from "./components";
import { Header } from "../common";

export const HomePage = () => {
	return (
		<Container
			maxWidth={false}
			sx={{
				bgcolor: "#231F20",
				width: "80vw",
				maxWidth: "80vw",
				margin: "0 10vw",
				padding: "0",
			}}
		>
			<HomePageBody />
		</Container>
	);
};
