const logo = require("../../images/file.png");

export const Logo = () => {
	return (
		<img
			style={{
				objectFit: "contain",
			}}
			src={logo}
			alt="Band Logo"
			height={"100%"}
			width={"100%"}
		/>
	);
};
