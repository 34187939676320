import { Container, Link, Typography, useTheme } from "@mui/material";
import { Image, Logo } from "./common";

export function BeatsPlaceholder() {
	const { palette } = useTheme();
	return (
		<Container
			sx={{
				height: "100vh",
				width: "100vw",
			}}
		>
			<Container
				sx={{
					height: "50vh",
				}}
			>
				<Logo />
			</Container>
			<Typography
				variant="h2"
				textAlign={"center"}
				fontWeight={"bold"}
				color={palette.primary.main}
			>
				Här kommer snart Beats 80s helt nya hemsida!
			</Typography>
			<Typography
				variant="h3"
				textAlign={"center"}
				color={palette.primary.main}
			>
				Tills dess kan ni följa allt vi gör på vår{" "}
				<Link href="https://www.facebook.com/beats.2024">Facebook</Link>!
			</Typography>
		</Container>
	);
}
