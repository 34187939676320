import { Email, Facebook, Phone } from "@mui/icons-material";
import { Box, Card, Container, Typography, useTheme } from "@mui/material";
import { useIsWindowHorizontal } from "../../hooks";
import { useScrollRefContext } from "../../../state/scroll-ref-context";
import { cardBG } from "../../util";
import { ContactPageItem } from "./contact-page-item";

export const ContactPageBody = () => {
	const isHorizontal = useIsWindowHorizontal();
	const { contactRef } = useScrollRefContext();
	return (
		<Container
			sx={{
				alignItems: "center",
				flexDirection: "column",
				padding: "10%",
			}}
			ref={contactRef}
		>
			<Container
				sx={{
					display: "flex",
					flexDirection: isHorizontal ? "row" : "column",
					flexWrap: "wrap",
					gap: "10vw",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<ContactPageItem
					headerText="Email"
					bodyText="boka@beats80.se"
					Icon={Email}
				/>
				<ContactPageItem
					headerText="Telefon"
					bodyText="070-1234567"
					Icon={Phone}
				/>
				<ContactPageItem
					headerText="Facebook"
					bodyText="BEATS 80"
					iconColor="#1877F2"
					Icon={Facebook}
				/>
			</Container>
		</Container>
	);
};
