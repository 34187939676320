import { Container } from "@mui/material";
import { HomePageImageList } from "./home-page-image-list";

export const HomePageBody = () => {
	return (
		<Container
			sx={{
				width: "100%",
				height: "100%",
			}}
		>
			<HomePageImageList />
		</Container>
	);
};
