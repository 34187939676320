import { Context, createContext, RefObject, useContext, useRef } from "react";

export interface ScrollRefContextParams {
	galleryRef: RefObject<HTMLDivElement>;
	contactRef: RefObject<HTMLDivElement>;
}

export const ScrollRefContext: Context<ScrollRefContextParams> = createContext({
	galleryRef: {} as RefObject<HTMLDivElement>,
	contactRef: {} as RefObject<HTMLDivElement>,
});

export const ScrollRefProvider = ScrollRefContext.Provider;

export const useScrollRefContext = () => useContext(ScrollRefContext);
