import { Button, Card, Container, Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { cardBG } from "../../util";
import { useIsWindowHorizontal } from "../../hooks";

export interface ContactPageItemProps {
	headerText: string;
	bodyText?: string;
	Icon: SvgIconComponent;
	iconColor?: string;
}

export const ContactPageItem = ({
	headerText,
	bodyText,
	Icon,
	iconColor = "primary",
}: ContactPageItemProps) => {
	const isHorizontal = useIsWindowHorizontal();
	return (
		<Button
			sx={{
				width: "25vh",
				minWidth: "25vw",
				minHeight: "25vw",
				backgroundColor: cardBG,
				padding: "16px",
				boxShadow: 1,
				height: "25vh",
			}}
		>
			<Container
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Icon
					fontSize="large"
					sx={{
						color: iconColor,
					}}
				/>
				<Typography
					variant="h5"
					fontWeight={"bold"}
					color="secondary"
					paddingTop={"1rem"}
				>
					{headerText}
				</Typography>
				<Typography
					variant="subtitle1"
					color="secondary"
					textTransform={"none"}
				>
					{bodyText}
				</Typography>
			</Container>
		</Button>
	);
};
