import { createTheme } from "@mui/material";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#EC008C",
		},
		secondary: {
			main: "#FEFC53",
		},
	},
});
