import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import { theme } from "./theme";
import { BeatsPlaceholder } from "./frontend/beats-placeholder";
import BeatsWebPage from "./frontend/beats-web-page";

const App = () => {
	return (
		<>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<BeatsPlaceholder />} />
						<Route path="/beats80sneakpeek" element={<BeatsWebPage />} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</>
	);
};

export default App;
