import { Container, useTheme } from "@mui/material";
import { ContactPageBody } from "./components/contact-page-body";

export const ContactPage = () => {
	const { palette } = useTheme();
	return (
		<Container
			maxWidth={false}
			sx={{
				overflowX: "hidden",
				backgroundColor: palette.primary.main,
				width: "80vw",
				margin: "0 10vw",
				marginBottom: "10vw",
			}}
		>
			<ContactPageBody />
		</Container>
	);
};
