import { Container, Typography } from "@mui/material";
import { useIsWindowHorizontal } from "../hooks";
import { Logo } from "./logo";

export const Header = () => {
	const isHorizontal = useIsWindowHorizontal();
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
			}}
		>
			{/* 			{isHorizontal && (
				<Container
					sx={{
						width: "25%",
					}}
				>
					<Typography>Här är en Text!</Typography>
				</Container>
			)} */}
			<Container
				sx={{
					height: "100vh",
				}}
			>
				<Logo />
			</Container>
			{/* 			{isHorizontal && (
				<Container
					sx={{
						width: "25%",
					}}
				>
					<Typography>Här är en annan text!</Typography>
				</Container>
			)} */}
		</Container>
	);
};
